import { withRouter } from 'react-router-dom';
import * as React from 'react';


export const GlobalContext = React.createContext('global');


export const StoreContext = React.createContext('store');
export const UIContext = React.createContext('ui');
export const ControlsContext = React.createContext('controls');
export const ThemeContext = React.createContext('theme');
export const ProjectContext = React.createContext('project');

  
/*
export function withGlobalContext(Component: React.ComponentType) {
    function withGlobalContext(props: any) {
      var history = props.history;
      return (
        <ProjectContext.Consumer>{(project:any)=> (
        <ControlsContext.Consumer>{controls=> (
        <UIContext.Consumer>{ ui => (
        <ThemeContext.Consumer>{ theme=> (
          <StoreContext.Consumer>{store=> (
            <Component {...props} store={store} theme={theme} ui={ui} controls={controls} project={project} history={history}/>
          )}</StoreContext.Consumer>
        )}</ThemeContext.Consumer>
        )}</UIContext.Consumer>
        )}</ControlsContext.Consumer>
        )}</ProjectContext.Consumer>
      )}
  return withRouter(withGlobalContext);
}

*/