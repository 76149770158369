import { default as React, useContext } from 'react';

import { AppBar, Toolbar, IconButton, Typography, Button  } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import Link from './Link'
import { GlobalContext } from './withGlobalContext';
import useReactRouter from 'use-react-router';

export function Header(props:any) {
    
	const global:any = useContext(GlobalContext);
	const {project,theme,api} = global;


	const router = useReactRouter();

	const styles = {
		root: {
			flexGrow: 1,
		},
		grow: {
			flexGrow: 1,
			color:"white"
		},
		menuButton: {
			marginLeft: -12,
			marginRight: 20,
		},
		logo:{
			height:24
		},
		heading:{
			color:"white"
		}
	};

	return <AppBar position="static" style={styles.root}>
		<Toolbar>
			<span style={styles.grow}><Link to="/"><Typography variant="h6" style={styles.heading}>{global.project.title}</Typography></Link></span>
	
		</Toolbar>
	</AppBar>



}