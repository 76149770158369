import * as React from "react";
import * as ReactDOM from "react-dom";
import * as reactRouterDom from 'react-router-dom'

import { Link as RouterLink } from 'react-router-dom'


import * as core from '@material-ui/core';

class Link extends React.Component<any,any> {

    render(){
       var styles = {
            base:{
              //  textDecoration:"none"
            } as React.CSSProperties
		}
    
        var style = Object.assign({}, styles.base, this.props.style);
        const MyLink = props => <RouterLink to={this.props.to} {...props} />

        return <core.Link component={MyLink} style={style} >
            {this.props.children}
        </core.Link>
    }
};

export default (Link);

/*
<reactRouterDom.Link onClick={this.props.onClick} style={style} to={this.props.to} >
    {this.props.children}
</reactRouterDom.Link>
*/