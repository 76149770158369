import React, { useContext } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Header} from '../../ui/Header'
import Footer from "../../ui/Footer";
import Hero from './Hero'
import How from './How'
import Why from './Why'
import Splash from './Splash'
import MoistureMinder from './MoistureMinder'
import GrowthGauge from './GrowthGauge'
import Signup from './Signup'
import WhoAreWe from './WhoAreWe'
import WhatWeOffer from "./WhatWeOffer"


export default function Landing(){

	const global:any = useContext(GlobalContext);
	const {project,theme} = global;

	return <React.Fragment>
		<Header />
			<Hero />
			<WhatWeOffer />
			<Splash />
		<Footer />
	</React.Fragment>  

}

//	<Signup />
//<MoistureMinder />
//<GrowthGauge />
//<WhoAreWe />