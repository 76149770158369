import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
//import * as worker from './worker';

ReactDOM.render(<App />, document.getElementById('root'));

function urlBase64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - base64String.length % 4) % 4);
	const base64 = (base64String + padding)
	  .replace(/-/g, '+')
	  .replace(/_/g, '/');
   
	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);
   
	for (let i = 0; i < rawData.length; ++i) {
	  outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
  }

//serviceWorker.register();
//worker.register();
const publicVapidKey = "BBe1bxsyOYL4snjlUmtrWZWQQ7mS0ym6UiZOuGueK22FfJ4Tqarkg14XQMTq3R4eZ-0xaQN51uRahsq1g6zcXac";
		

//if ('serviceWorker' in navigator) {
	run().catch(error => console.error("failed",error));
  //}
  
  async function run() {
	console.log('Registering service worker push');
	const registration = await navigator.serviceWorker.
	  register('/push-worker.js', {scope: '/'});
	console.log('Registered push service worker');
  
	const subscription = await registration.pushManager.
	  subscribe({
		userVisibleOnly: true,
		applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
	  });
	console.log('Registered push');

		if(window.location.hostname == "localhost")
			var domain = "http://localhost:80";
		else
			var domain = "https://api.greenlabs.eco";

  
	console.log('Sending push');
	await fetch(domain+'/subscribe', {
	  method: 'POST',
	  body: JSON.stringify({user:1,data:subscription}),
	  headers: {
		'content-type': 'application/json'
	  }
	});
	console.log('Sent push');
  }