import React from 'react';

import { createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles'; 
import red from '@material-ui/core/colors/red';

import { BrowserRouter, HashRouter, Switch, Route, Redirect, withRouter } from 'react-router-dom';

import { StoreContext, UIContext, ControlsContext, ThemeContext, ProjectContext } from './ui/withGlobalContext';

import {GlobalContext} from "./ui/withGlobalContext";
import Landing from './page/Landing';
import Card from './page/Card';
import Graph from './page/Graph';
import Biz from './page/Biz';
import {API} from './lib/API';


export default class App extends React.Component<any,any>{

  constructor(props:any){
    super(props);
    let api = new API();
    api.onChange = () => this.forceUpdate();


    const theme:any = createMuiTheme({
      palette: {
        primary: {
          main: '#0057b8'
        },
        secondary: red
      },
    });


    const project = {
      title:"Elektrotech",
      tagline:"A Fusion of Technology and Nature",
      website:"www.elektrotech.com.au",
      email:"kevinbrown@elektrotech.com.au",
      location:"Melbourne, Australia",
      logo:"/logo/blue.svg"
    }


    this.state = {
      api,
      project,
	  theme
    }

  }

  render(){

    let main_pages =  <BrowserRouter >
      <Switch>
        <Route exact path='/' render={props => <Landing /> } />
      </Switch>
    </BrowserRouter >

	let state:any = this.state;

    return (
      <ThemeProvider theme={this.state.theme}>
      <CssBaseline />
      <GlobalContext.Provider value={state}>
      <div>{main_pages}</div>
      </GlobalContext.Provider>
    </ThemeProvider>

    );
  }
}
