
import { AppBar, Toolbar, IconButton, Typography, Button, Grid,CardActions,CardContent,CardMedia,Card, Container  } from '@material-ui/core'
import React, { useContext } from 'react';
import { GlobalContext } from './withGlobalContext';

export default function Footer(){
    
  const global:any = useContext(GlobalContext);
  const {project,theme} = global;


    let styles = {
        footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
        }
    }

     return <footer style={styles.footer}>
            <Typography variant="h6" align="center" gutterBottom>{global.project.email}</Typography>
            <Typography variant="subtitle1" align="center" color="textSecondary" component="p">{global.project.title} 2020 - Melbourne, Australia</Typography>
        </footer>

}

