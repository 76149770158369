import moment from 'moment';


if(window.location.hostname == "localhost")
	var domain = "http://localhost:80";
else
	var domain = "https://api.greenlabs.eco";


class Session{
  user:any;
  units:Unit[];
  active:boolean
  api:API;

  constructor(api:API){
    this.user = null;
    this.units = [];
	this.active = false;
	this.api = api;
  }

  setUser(user:any){
    this.user = user;
    this.active = true;
  }

  setUnits(units:any){
    this.units = units.map((u:any) => new Unit(u,this.api));
  }

  getUnit(id:string){
    for(let unit of this.units)
      if(unit.id == id)
          return unit;

    throw "no unit of id"+id;
  }

	getUnits(){
		return this.units;
	}


	toJSON(){
		return {id:this.user.session_id,owner:this.user.id, key:this.user.key};
	}

}

let sensorsDef = [
  {icon:"/sensors/temp.svg",label:"Moisture",units:"v",key:"moisture",decimalPlaces:1,domain:[0,40]},
  {icon:"/sensors/humidity.svg",label:"RSSI",units:"db",key:"rssi",decimalPlaces:1,domain:[-100,0]},
  {icon:"/sensors/bolt.svg",label:"Uptime",units:"msec",key:"uptime",decimalPlaces:0,domain:[0,2000]},

]



export class Unit{
  id:string;
  label:string;
  sensors:Sensor[];
  samples:any;
  firmwareVersion:string;
  firmwareDate:string;
  lastSeen:moment.Moment;

  online:boolean;
  api:API; 

  constructor(data:any,api:API){
	this.api = api;
    this.id = data.id;
	this.label = data.label;
	
	this.firmwareVersion = data.firmwareVersion;
	this.firmwareDate = data.firmwareDate;

    this.lastSeen = moment(data.lastSeen);
    this.sensors = [];
    this.samples = [];

    for(let sensor of sensorsDef){
      let s = data.data[sensor.key];
      if(typeof s != "undefined" && s != 0){
        this.sensors.push(new Sensor(sensor,s));
      }
    }


    const twoMinutesAgo = moment().subtract(2, 'minutes')
    this.online = this.lastSeen.isAfter(twoMinutesAgo);


  }

 async send(command:any){
	await this.api.sendUnit(this,command);
  }

  getSensorString(){
    let sensors = this.sensors.map(s => s.toString())
    let str = sensors.join(" ");
    return str;

  }

}

export class Sensor{
  key:string;
  label:string;
  units:string;
  value:number;
  decimalPlaces:number
  domain:any;
  icon:string;


  constructor(def:any,value:number){
    this.key = def.key;
    this.label = def.label;
    this.units = def.units;
    this.domain = def.domain;
    this.decimalPlaces = def.decimalPlaces;
    this.icon = def.icon;
    this.value = value;

  }

  toString(){

    let scale =  Math.pow(10,this.decimalPlaces)
    let value =  Math.round( this.value * scale ) / scale;
    return `${value}${this.units}`;
  }

}


export class API{
    
    address:string;
	session:Session;
	units:Unit[];

    onChange:Function|undefined;

    constructor(){
      this.address = domain;
      this.session = new Session(this);
	  //this.login();
	  this.units = [];
	  
	  this.setup();
	}
	

	setUnits(units:any){
		this.units = units.map((u:any) => new Unit(u,this));
	}

	setUnitSamples(samples:any){
		//console.log(samples);
		for(let unit of this.units)
			unit.samples = samples.filter( s => s.id == unit.id);
	
	}

    request(path:string,data?:any):Promise<any>{
      let options:any = {
        headers:{}
      };
      options.cors = "no-cors";

      if(typeof data != "undefined"){
        options.method = "POST";
        options.body = JSON.stringify(data);
        options.headers['Content-Type'] = "application/json; charset=UTF-8";
      }

      if(this.session.active){
        options.headers["x-session"] = JSON.stringify(this.session.toJSON());
      }
       
      return fetch(`${this.address}/${path}`,options) 
        .then(data => { 
			return data.text();
		})
		.then(str => {
			console.log(str);
			return JSON.parse(str);
		})
        .then( res =>{
        if('error' in res)
          throw res.error;
       // else
       // console.log(res);

          return res;
    });
    }
    

    async login(){
      try{
        let session = await this.request("session/login",{email:"keith@epidev.com",password:"qwerty12"})
        this.session.setUser(session);
        await this.getUnits();
       // console.log("units",this.session.getUnits());
        this.changed();

      }catch(E){
        return E;
      }
    }

	async setup(){
		await this.getUnits();
		await this.getSamples();
		this.changed();
	}

   async getUnits(){
		let units =  await this.request(`unit`);
		console.log(units);
		this.setUnits(units);

        for(let unit of units){
          //this.getUnitSamples(unit.id);
        }
	}
	
	async sendUnit(unit:Unit,command:any){
         await this.request(`api/multipoint/${unit.id}`,command);
    }

    changed(){
      if(typeof this.onChange != "undefined")
        this.onChange();
    }

    async getSamples(){
      let samples =  await this.request(`sample`);
      this.setUnitSamples(samples);
  }
    

}