import { Container, Grid, Typography, Box, Card, CardContent } from '@material-ui/core';
import ListAlt from '@material-ui/icons/ListAlt';
import Smartphone from '@material-ui/icons/Smartphone';
import FilterVintage from '@material-ui/icons/FilterVintage';

import ShowChart from '@material-ui/icons/ShowChart';
import React, { useContext } from 'react';
import { GlobalContext } from '../../ui/withGlobalContext';
import Classes from "./Classes"; 
import { makeStyles } from '@material-ui/core/styles';
  
export default function Splash() {
	const global: any = useContext(GlobalContext);
	const { project, theme } = global;
    let classes = Classes();


	let imgStyle = {
		width:"100%"
	}

	return <React.Fragment><div className={classes.altSection}><img style={imgStyle} src="/img/splash.jpg" /></div></React.Fragment>

}