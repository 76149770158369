import { Container, Grid, Typography, Box, Card, CardContent } from '@material-ui/core';
import ListAlt from '@material-ui/icons/ListAlt';
import Settings from '@material-ui/icons/Settings';
import ShowChart from '@material-ui/icons/ShowChart';

import { Link as RouterLink } from 'react-router-dom'
import Link from "../../ui/Link";
import React, { useContext } from 'react';
import { GlobalContext } from '../../ui/withGlobalContext';
import Classes from "./Classes"; 
import { makeStyles } from '@material-ui/core/styles';
  
export default function WhatWeOffer() {
	const global: any = useContext(GlobalContext);
	const { project, theme } = global;
    let classes = Classes();

	const styles = makeStyles(theme => ({
		icon: {
			fontSize: 100,
			margin: 20
		},
		iconWrapper: {
			textAlign: "center"
		}
	}))();


	let imgStyle = {
		width:"60%",
		margin:"auto",
		display:"block"
	}

	return <React.Fragment>
		<Container maxWidth="lg">
			<div className={classes.section}>
			<Grid container spacing={10}>
				<Grid item md={12} xs={12}>
				
						<Typography variant="body1" >Elektrotech provides consultancy services specialising in electricity metering activities in the Australian National Market. Specializing in supporting prospective MPB/MDP's and entities seeking AEMO accreditation through to go live and periodic audit preparation.</Typography>
                        <Typography variant="body1" >
                            <ul>
                                <li>Smart meter installation and exchange</li>
                                <li>Maintenance and inspections</li>
                                <li>CT Commissioning, Testing and validation</li>
                                <li>Technical consulting services</li>
                                <li>Field compliance auditing</li>
                                <li>Training and competency assessment</li>
                                <li>Responsible person/Technical nominate</li>
                                <li>Technical system and product review</li>
                            </ul>
                            </Typography>
							<Typography variant="body1" >If you would like more information you may contact us on <a href="mailto:kevinbrown@elektrotech.com">kevinbrown@elektrotech.com.au</a></Typography>
                    	<br/>
						
				</Grid>
			</Grid>

			</div>
		</Container>
	</React.Fragment>

}